
import React from 'react';
import logo from './logo.png';

// TODOS: LOGO NAME ANIMATION...
export default function Logo() {
    return (
        <img
            width="200"
            height="27"
            fill={'primary'}
            src={logo}
            alt="curatemails"
        />
    );
}